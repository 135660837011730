:root {
    --color-primary: #3490dc;
    --color-secondary: #6574cd;
    --color-background: #ffffff;
    --color-text: #2d3748;
    --color-text-light: #718096;
    --color-border: #e2e8f0;
    --color-background-opacity: 255,255,255, 0.9
}

.dark {
    --color-primary: #60a5fa;
    --color-secondary: #818cf8;
    --color-background: #111827;
    --color-text: #f3f4f6;
    --color-text-light: #d1d5db;
    --color-border: #374151;
    --color-background-opacity: 0, 0, 0, 0.7
}

body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
    background-color: var(--color-background);
    color: var(--color-text);
    transition: background-color 0.3s, color 0.3s;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
}

.header {
    background-color: var(--color-background);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    padding: 1rem 0;
}

.header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--color-text);
    text-decoration: none;
}

.nav {
    display: none;
}

.nav-link {
    color: var(--color-text-light);
    text-decoration: none;
    margin-left: 1rem;
    cursor: pointer;
}

.nav-link:hover {
    color: var(--color-primary);
}

.theme-toggle {
    background: none;
    border: none;
    cursor: pointer;
    color: var(--color-text);
}

.lang-switcher {
    display: flex;
    gap: 0.5rem;
}

.lang-button {
    padding: 0.25rem 0.5rem;
    border: none;
    border-radius: 0.25rem;
    background-color: var(--color-primary);
    color: white;
    cursor: pointer;
}

.lang-button:hover {
    opacity: 0.8;
}

.hero {
    background-image: url("static/images/hero.png");
    color: white;
    padding: 10rem 0;
    text-align: center;
    background-position: center;
    background-size: cover;
}

.hero-title {
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

.hero-subtitle {
    font-size: 1.25rem;
    margin-bottom: 2rem;
}

.button {
    display: inline-block;
    background-color: white;
    color: var(--color-primary);
    padding: 0.75rem 1.5rem;
    border-radius: 9999px;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s;
    cursor: pointer;
}

.button:hover {
    background-color: var(--color-text-light);
    color: var(--color-background);
}

.section {
    padding: 4rem 0;
}

.section-title {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 2rem;
}

.grid {
    display: grid;
    gap: 2rem;
}

.card {
    background-color: var(--color-background);
    border: 1px solid var(--color-border);
    border-radius: 0.5rem;
    padding: 1.5rem;
    text-align: center;
}

.card-icon {
    font-size: 3rem;
    margin-bottom: 1rem;
}

.card-title {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
}

.form {
    max-width: 32rem;
    margin: 0 auto;
}

.form-group {
    margin-bottom: 1rem;
}

.form-label {
    display: block;
    margin-bottom: 0.5rem;
    color: var(--color-text-light);
}

.form-input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid var(--color-border);
    border-radius: 0.25rem;
    background-color: var(--color-background);
    color: var(--color-text);
}

.form-submit {
    width: 100%;
    padding: 0.75rem;
    background-color: var(--color-primary);
    color: white;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
}

.form-submit:hover {
    background-color: var(--color-secondary);
}

.footer {
    background-color: #111827;
    color: white;
    padding: 2rem 0;
}

.footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.footer-link {
    color: var(--color-text-light);
    text-decoration: none;
    margin: 0 0.5rem;
}

.footer-link:hover {
    color: white;
}

.menu-toggle {
    display: block;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: var(--color-text);
}

@media (min-width: 640px) {
    .nav {
        display: flex;
    }
    .grid {
        grid-template-columns: repeat(2, 1fr);
    }
    .menu-toggle {
        display: none;
    }
}

@media (min-width: 1024px) {
    .grid {
        grid-template-columns: repeat(3, 1fr);
    }
    .footer-content {
        flex-direction: row;
        justify-content: space-between;
    }
}

.nav.open {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: var(--color-background);
    padding: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.nav.open .nav-link {
    margin: 0.5rem 0;
}

.text-uppercase {
    text-transform: uppercase;
}

.no-underline {
    text-decoration: none;
    color: var(--color-text);
}

.ocean {
    height: 5%;
    width:100%;
    position:absolute;
    bottom:0;
    left:0;
    background: #015871;
    opacity: 0;
}

.wave {
    background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/85486/wave.svg) repeat-x;
    position: absolute;
    top: -198px;
    width: 6400px;
    height: 198px;
    transform: translate3d(0, 0, 0);
}

.wave-container:hover h3, .wave-container:hover p  {
    color: #fff
}

.wave-container:hover .ocean {
    opacity: 1;
}

.wave-container:hover .wave {
    animation: wave 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite;
}

.wave-container:hover .wave:nth-of-type(2) {
    top: -175px;
    animation: wave 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) -.125s infinite, swell 7s ease -1.25s infinite;
    opacity: 1;
}

@keyframes wave {
    0% {
        margin-left: 0;
    }
    100% {
        margin-left: -1600px;
    }
}

@keyframes swell {
    0%, 100% {
        transform: translate3d(0,-25px,0);
    }
    50% {
        transform: translate3d(0,5px,0);
    }
}

.wave-container {
    position: relative;
    overflow: hidden;
}

.wave-container h3, .wave-container p {
    position: relative;
}

.theme-switch {
    position: relative;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
}

.theme-switch .slider {
    background-color: #e2e8f0; /* light gray */
    border-radius: 999px;
    width: 56px;
    height: 32px;
    position: relative;
    transition: background-color 0.3s ease-in-out;
}

.theme-switch .slider .dot {
    position: absolute;
    top: 4px;
    left: 4px;
    width: 24px;
    height: 24px;
    background-color: #ffffff;
    border-radius: 50%;
    transition: transform 0.3s ease-in-out;
}

.theme-switch input:checked + .slider {
    background-color: #4a5568; /* dark gray */
}

.theme-switch input:checked + .slider .dot {
    transform: translateX(24px);
}

.theme-switch input {
    display: none !important;
}

.contact-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /* gap: 20px; */
    /* padding: 20px; */
    position: relative;
    align-items: stretch;
    min-height: 360px;
}

.contact-container .card {
    flex: 1 1 45%;
    max-width: 600px;
    min-width: 300px;
    border: none;
    box-shadow: none;
    border-radius: 0;
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 5px; */
    padding: 15px;
    position: absolute;
    /* height: 100%; */
    top: 0;
    bottom: -4px;
    /* box-sizing: border-box; */
    background-color: rgba(var(--color-background-opacity));
    display: flex;
    align-items: center;
}

#contact .container {
    padding: 0;
}